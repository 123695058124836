import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/_core/api/google-analytics.service';

@Component({
  selector: 'app-give-button',
  templateUrl: './give-button.component.html',
  styleUrls: ['./give-button.component.scss'],
})
export class GiveButtonComponent implements OnInit {
  @Input() isLink: boolean = false;
  @Input() url: string = '';
  @Input() target: string = '_blank';
  @Input() isPrimary: boolean = false;
  @Input() disabled: boolean = false;
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private gaService: GoogleAnalyticsService) {}

  ngOnInit(): void {}

  linkOut(): void {
    this.gaService.trackButtonClick('give_button', 'give_button', 'Give button click', 'Give button click');
    window.open(this.url, this.target);
  }
}
